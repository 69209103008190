import {IStyle} from '@wix/yoshi-flow-editor'
import {WEEK_LENGTH, WEEKS_PER_CALENDAR_MONTH} from '@wix/wix-events-commons-statics'
import type {AppProps} from '../components/app/interfaces'
import {CalendarLayout, State} from '../types/state'
import {getEventById, shouldShowDemoEvents, shouldShowRealEvents} from './events'
import {getCalendarWeekStartDay} from './list-settings'

export const getEventDetailsStyleHash = (context: AppProps) => {
  const eventDetailsSettings = {
    booleans: ['listShowImage', 'listShowFullDate', 'listShowLocation', 'listShowDescription', 'listShowSocialShare'],
    numbers: [
      'calendarDetailsTitleTextSize',
      'calendarDetailsDateLocationTextSize',
      'calendarDetailsDescriptionTextSize',
      'multiImageRatio',
      'calendarWeekStartDay',
    ],
  }

  const style: IStyle = context.host.style

  const settings = Object.entries(style.styleParams).reduce((result, [type, params]) => {
    if (eventDetailsSettings[type]) {
      result[type] = Object.entries(params).reduce((typeResult, [key, value]) => {
        if (eventDetailsSettings[type].includes(key)) {
          typeResult[key] = value
        }

        return typeResult
      }, {})
    }

    return result
  }, {})

  return JSON.stringify(settings)
}

export const isCalendarPageLoaded = ({loadedPages}: CalendarLayout, referenceDate: string) =>
  loadedPages.includes(referenceDate)

export const isCalendarPageLoading = ({loading}: CalendarLayout) => !!loading

export const getWeekdaysFromStartWeekday = (state: State) => {
  const weekDayNames = state.calendarLayout.weekDayNames
  const startWeekDay = getCalendarWeekStartDay(state.component)
  return [...weekDayNames.slice(startWeekDay), ...weekDayNames.slice(0, startWeekDay)]
}

export const getDaysInGridMonth = (state: State, referenceDate: string) => {
  const startWeekDay = getCalendarWeekStartDay(state.component)
  const startOfMonthWeekday = state.calendarLayout.days[referenceDate].weekDay
  let showPastMonthDaysCount = startOfMonthWeekday - startWeekDay
  if (showPastMonthDaysCount < 0) {
    showPastMonthDaysCount += WEEK_LENGTH
  }
  const allDays = Object.keys(state.calendarLayout.days).sort()
  const firstDayIndex = allDays.indexOf(referenceDate) - showPastMonthDaysCount

  return allDays.slice(firstDayIndex, firstDayIndex + WEEKS_PER_CALENDAR_MONTH * WEEK_LENGTH)
}

export const getDaysInMonth = (state: State, referenceDate: string) =>
  Object.keys(state.calendarLayout.days)
    .filter(day => state.calendarLayout.days[day].month === referenceDate)
    .sort()

export const getDaysInMonthWithEvents = (state: State, referenceDate: string) =>
  getDaysInMonth(state, referenceDate).filter(day => !!getCalendarEvents(state)[day])

export const getGridCellLocation = (state: State, day: string) => {
  const days = getDaysInGridMonth(state, getReferencedMonth(state))
  const cellIndex = days.indexOf(day)
  const weekIndex = Math.floor(cellIndex / WEEK_LENGTH)
  const weekDayIndex = cellIndex % WEEK_LENGTH
  return {weekDayIndex, weekIndex}
}

export const getCalendarEvents = (state: State): Dictionary<string[]> => {
  if (shouldShowRealEvents(state)) {
    return state.calendarLayout.events || {}
  } else if (shouldShowDemoEvents(state)) {
    return state.calendarLayout.demoEvents || {}
  }
  return {}
}

export const getEventsForDay = (state: State, day: string) => {
  const eventIds = getCalendarEvents(state)[day] ?? []
  return eventIds.map(eventId => getEventById(state, eventId)).filter(Boolean)
}

export const isDayInCurrentMonth = (state: State, day: string) =>
  state.calendarLayout.days[day]?.month === getReferencedMonth(state)

export const getReferencedMonth = (state: State) => state.calendarLayout.referenceDate
export const getReferencedMonthName = (state: State) =>
  state.calendarLayout.month[getReferencedMonth(state)].fullMonthText
export const getMonthOfDate = (state: State, day: string) => state.calendarLayout.days[day]?.month

export const getNextMonth = (state: State) => state.calendarLayout.month[getReferencedMonth(state)].next
export const getPreviousMonth = (state: State) => state.calendarLayout.month[getReferencedMonth(state)].previous
export const getToday = (state: State) => state.calendarLayout.today

export const getDayMonthText = (state: State, day: string) => state.calendarLayout.days[day].dayMonthText
export const getDayNumber = (state: State, day: string) => state.calendarLayout.days[day].dayNumber

export const getSelectedDate = (state: State) => state.calendarLayout.monthly?.selectedDate
export const getSelectedEventId = (state: State) => state.calendarLayout.monthly?.selectedEventId

export const isAnyPageLoaded = (state: State) => {
  const referenceDate = getReferencedMonth(state)
  return isCalendarPageLoaded(state.calendarLayout, referenceDate)
}
